<template>
  <div class="newsMessage">
    <banner></banner>
    <div v-loading="loading">
      <div class="news">
        <div class="title tc">
          {{ newsList.title_en | priorFormat(newsList.title_zh, LOCALE) }}
        </div>
        <div class="newsPulish clearfix">
          <div class="fl">{{ $t("publishTime") }}:</div>
          <div class="mr ml fl">
            {{ newsList.created_time | secondFormat("LL") }}
          </div>
        </div>
        <div v-html="contentHtml" class="main"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import banner from '~exp/components/common/banner'
  export default {
    name: "index",
    components: { banner },
    data() {
      return {
        meeting_news_id: '',
        loading: '',
        newsList: [],
        contentHtml: "",
      }
    },
    mounted() {
      let routerParams = this._decode(this.$route.query.parameter);
      this.meeting_news_id = routerParams.meeting_news_id
      this.getList();
    },
    methods: {
      async getList() {
        this.loading = true;
        let params = {
          meeting_id: this.MEETING_ID,
          meeting_news_id: this.meeting_news_id,
        };
        let meetData = await this.$store.dispatch("home/getMeetNewsList", params);
        if (meetData.success) {
          this.loading = false;
          this.newsList = meetData.data[0];
          this.contentHtml =
            this.LOCALE == "en"
              ? this.newsList.content_en
              : this.newsList.content_zh;
        } else {
          this.loading = false;
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .newsMessage {
    background: #f3f7fa;
    min-height: calc(121vh - 205px);
    .title {
      font-size: 32px;
    }
    .news {
      padding: 30px 0;
      max-width: 1300px;
      margin: 0 auto;
    }
    .main {
      margin-top: 60px;
    }
    .mainTxt {
      margin-top: 10px;
      text-indent: 2em;
      line-height: 25px;
      text-align: justify;
    }

    .newsPulish {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      margin-top: 10px;
      font-size: 16px;
    }
  }
  .mr {
    margin-right: 20px;
  }
  .ml {
    margin-left: 5px;
  }
  .weXinImg {
    width: 20px;
  }
</style>